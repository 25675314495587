// fix issue related to empty value and left/ right dotted incomplete display.
ag-grid-angular div:not(.row-pinned, .ag-full-width-row).ag-cell {
    height: 100% !important
}

.ag-aria-description-container {
  display: none !important;
}

// [LS-5659] This issue seems a combination of ag-grid and the gui package.
sc-correspondence-list {
  [col-id="contextMenu"] {
    border-left-width: 0;
  }

  // [LS-5743]
  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    --ag-range-selection-border-style: solid;
    --ag-range-selection-border-color: transparent;
    border-width: 0;
  }
}
